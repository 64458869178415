import React, { useEffect, useState } from "react";
import Main from "../../components/dashboard/Main";
import {
  Outlet,
  Route,
  useLocation,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import LogoWhite from "../../assets/images/logo-cardio4all-white.svg";
import Logo from "../../assets/images/logo-cardio4all.svg";
import FooterLogo from "../../assets/images/cardio4all-footer.svg";
import instagramIcon from "../../assets/images/instagram-white.svg";
import facebookIcon from "../../assets/images/facebook-white.svg";
import footerBg from "../../assets/images/fundo-footer.png";
import "./MainLayout.css";
import {
  Layout,
  Button,
  theme,
  Menu,
  Row,
  Col,
  Divider,
  Drawer,
  Popover,
} from "antd";
import { LoginOutlined, LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  FileImageOutlined,
  AudioOutlined,
} from "@ant-design/icons";
import { Footer } from "antd/es/layout/layout";
import DrawerMenuMobile from "./DrawerMenuMobile";
import axios from "axios";
import endpoints from "../../services/endpoints";
import api from "../../services/api";
const { Header, Sider, Content } = Layout;

const AuthGuard = ({ user, isLoggedIn, handleLogin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userIsValid, setUserIsValid] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      console.log(token);
      axios
        .get(endpoints.user.read, {
          params: { token },
        })
        .then((res) => {
          api.axiosInit(token).then((result) => {
            setUserIsValid(true);
            localStorage.setItem("token", token);
            console.log(location);
            handleLogin(res.data.token_decoded, location.pathname);
          });
        })
        .catch((err) => {
          console.log(err);
          setUserIsValid(false);
        });
    } else {
      if (!isLoggedIn) {
        return navigate(
          `/login?redirect=${location.pathname}${location.search}`
        );
      }
    }
  }, [location]);

  return <Outlet />;
};
export default AuthGuard;
