import React, { useEffect, useState } from "react";
import { Col, Row, Button, Tabs, Form, Input, Statistic, notification } from "antd";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import endpoints from "../../../services/endpoints";
import config from "../../../services/config";

import CalculatorIcon from "../../../assets/images/calculadoras-icon.svg";
import ClinicCasesIcon from "../../../assets/images/casos-clinicos-icon.svg";
import GuidesIcon from "../../../assets/images/guias-praticos-icon.svg";
import AppStoreImage from "../../../assets/images/app-store.svg";
import GooglePlayImage from "../../../assets/images/google-play.svg";
import CursoImage from "../../../assets/images/Destaque-Curso-Gratuito.jpg";
import NewScienceImage from "../../../assets/images/Destaque-New-Science.jpg";
import VideocastsImage from "../../../assets/images/Destaque-Videocasts.jpg";
import MockupImage from "../../../assets/images/Cardio4All-App-Destaque@2x.png";

import "./HomeWhiteSection.css";

const { Countdown } = Statistic;

const HomeWhiteSection = () => {
  const [calculators, setCalculators] = useState([]);
  const [guides, setGuides] = useState([]);
  const [clinicalCases, setClinicalCases] = useState([]);
  const [articles, setArticles] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    axios
      .get(endpoints.info.read)
      .then((res) => {
        console.log(res);
        setCalculators(res.data[0]);
        setGuides(res.data[1]);
        setArticles(res.data[2]);

        const sorted = res.data[3].sort((a, b) => {
          return a.position - b.position;
        });

        for (let i = 0; i < sorted.length; i++) {
          let today = dayjs();
          let dayOfPublish = dayjs(sorted[i].publish_date);
          if (today.diff(dayOfPublish) > 0) {
            sorted[i].is_active = true;
          } else {
            sorted[i].is_active = false;
          }
        }

        setClinicalCases(sorted);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleCalcDate(item) {
    console.log("OVEEEER");
    let newData = JSON.parse(JSON.stringify(clinicalCases));
    let filteredObject = clinicalCases.filter((i) => i.id === item.id)[0];
    if (filteredObject) {
      filteredObject.is_active = true;
    }
    const i = clinicalCases.findIndex((x) => x.id === item.id);
    newData[i] = filteredObject;
    setClinicalCases(newData);
  }

  const SwipeCalculators = (
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={20}
      grabCursor={true}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: "auto",
          width: "auto",
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
      className="mySwiper d-flex jc-start ai-start mt-30"
    >
      {calculators.slice(0, 3).map((card, index) => {
        return (
          <SwiperSlide key={"swiper-card-" + card.id} id={card.id}>
            <Row align={"bottom"}>
              <Col className="swipe-card p-20" span={24}>
                <div className="d-flex flex-column jc-sb ai-center h-100">
                  <div>
                    <p className="swipe-card-content f-18 bold m-0 ellipsisTwo text-center">{card.name}</p>
                    <p className="swipe-card-content f-18 mt-10 ellipsis-destaque ellipsisTwo">{card.description}</p>
                  </div>
                  <div>
                    <Link className="red" to={card.slug} type="text">
                      Saber mais
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );

  const SwipeGuides = (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      grabCursor={true}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
      className="mySwiper d-flex jc-start ai-start mt-30"
    >
      {guides.map((card, index) => {
        return (
          <SwiperSlide key={"swiper-card-" + card.id} id={card.id}>
            <Row align={"bottom"} className="swipe-card">
              <Col
                span={8}
                className="h-100"
                style={{
                  backgroundImage: `url(${config.server_ip}/assets/media/${card.image})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></Col>
              <Col span={16} className="p-20 h-100">
                <div className="d-flex flex-column jc-sb h-100">
                  <div>
                    <p className="swipe-card-content f-18 bold m-0 ellipsisThree">{card.title}</p>
                  </div>
                  <div>
                    <Link className="red" to={`/guias-praticos/${card.slug}`} type="text">
                      Saber mais
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );

  const SwipeCases = (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      grabCursor={true}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
      className="mySwiper d-flex jc-start ai-start mt-30"
    >
      {clinicalCases.map((card, index) => {
        return (
          <SwiperSlide key={"swiper-card-" + card.id} id={card.id} className={`swiper-card`}>
            <Row align={"bottom"} className={`swipe-card ${card.is_active ? "active" : "inactive"}`}>
              <Col
                span={8}
                className="h-100 d-flex jc-end ai-end p-0"
                style={{
                  backgroundImage: `url(${config.server_ip}/assets/media/${card.image})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  filter: card.is_active ? "none" : "grayscale(100%)",
                }}
              >
                <div className="d-flex white bold f-60 mr-10" style={{ lineHeight: "50px" }}>
                  {card.position}
                </div>
              </Col>
              <Col span={16} className="p-20 h-100">
                <div className="d-flex flex-column jc-sb h-100">
                  <div>
                    <p className="f-22 bold m-0" style={{ color: card.is_active ? "#0E426A" : "#9C9DA1" }}>
                      {card.name}
                    </p>
                    <p className="mt-10" style={{ color: card.is_active ? "#87A1B5" : "#9C9DA1" }}>
                      {card.type}
                    </p>
                  </div>
                  <div>
                    {card.is_active ? (
                      <Link to={`/casos-clinicos/${card.slug}`} type="text" style={{ color: "#F04C4B" }}>
                        <p>Participar</p>
                      </Link>
                    ) : (
                      <p className="bold" style={{ color: card.is_active ? "#87A1B5" : "#9C9DA1" }}>
                        Dísponvel a <span className="text-uppercase">{dayjs(card.publish_date).format("DD MMM")}</span>
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.user.subscribeNewsletter, {
        data: values,
      })
      .then((res) => {
        notification.success({
          message: "Feito!",
          description: "Já está inscrito na nossa newsletter!",
        });
        setIsButtonLoading(false);
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
        notification.err({
          message: "Oops!",
          description: "Algo de errado aconteceu, por favor tente novamente!",
        });
        setIsButtonLoading(false);
      });
  }

  return (
    <>
      <Row className={`${windowWidth < 1000 ? "mt-30" : "mt-100"} container`}>
        <Col span={24}>
          <Tabs
            tabBarStyle={{ border: 0, outline: 0 }}
            tabBarGutter={100}
            id="home__tabs"
            defaultActiveKey="1"
            centered
            items={[
              {
                label:
                  windowWidth > 600 ? (
                    <p className="f-26 m-0 bold">Calculadoras</p>
                  ) : (
                    <div className="d-flex flex-column jc-center ai-center">
                      <img src={CalculatorIcon} />
                      <p className="f-12 mt-10 text-center">
                        Calculadoras
                        <br />
                        científicas
                      </p>
                    </div>
                  ),
                key: 0,
                children: (
                  <>
                    {SwipeCalculators}
                    <Row span={24} className="d-flex jc-center ai-center mt-40">
                      <Link to="/calculadoras">
                        <Button className="btn-ver-mais">Ver mais calculadoras</Button>
                      </Link>
                    </Row>
                  </>
                ),
              },
              {
                label:
                  windowWidth > 600 ? (
                    <p className="f-26 m-0 bold">Guias práticos</p>
                  ) : (
                    <div className="d-flex flex-column jc-center ai-center">
                      <img src={GuidesIcon} />
                      <p className="f-12 mt-10 text-center">
                        Guias
                        <br /> práticos
                      </p>
                    </div>
                  ),
                key: 1,
                children: (
                  <>
                    {SwipeGuides}
                    <Row span={24} className="d-flex jc-center ai-center mt-40">
                      <Link to="/guias-praticos">
                        <Button className="btn-ver-mais">Ver mais guias</Button>
                      </Link>
                    </Row>
                  </>
                ),
              },
              clinicalCases.length > 0
                ? {
                    label:
                      windowWidth > 600 ? (
                        <p className="f-26 m-0 bold">Casos clínicos</p>
                      ) : (
                        <div className="d-flex flex-column jc-center ai-center">
                          <img src={ClinicCasesIcon} />
                          <p className="f-12 mt-10 text-center">
                            Casos
                            <br />
                            clínicos
                          </p>
                        </div>
                      ),
                    key: 2,
                    children: (
                      <>
                        {SwipeCases}
                        <Row span={24} className="d-flex jc-center ai-center mt-40">
                          <Link to="/casos-clinicos">
                            <Button className="btn-ver-mais">Ver mais casos clínicos</Button>
                          </Link>
                        </Row>
                      </>
                    ),
                  }
                : {},
            ]}
          />
        </Col>
      </Row>
      <Row className="principal_items_parent mt-40">
        <Row gutter={[24]} className="container d-flex jc-center ai-center">
          <Col xs={24} sm={12} md={12} lg={8} xl={8} className="mt-10">
            <img src={NewScienceImage} className="h-100 w-100" />
            <div className="principal_item pr-24">
              <p className="mt-30 mr-30 text-right white bold f-30 mobile-f-22">
                New
                <br />
                Science
              </p>
              <Link to="/artigos">
                <Button type="primary" size="large">
                  Ver mais
                </Button>
              </Link>
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8} className="mt-10">
            <img src={VideocastsImage} className="h-100 w-100" />
            <div className="principal_item pr-24">
              <p className="mt-30 mr-30 text-right white bold f-30 mobile-f-22">Videocasts</p>
              <Link to="/videocasts">
                <Button type="primary" size="large">
                  Ver mais
                </Button>
              </Link>
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8} className="mt-10">
            <img src={CursoImage} className="h-100 w-100" />
            <div className="principal_item pr-24">
              <p className="mt-30 mr-30 text-right white bold f-30 mobile-f-22">
                Curso
                <br />
                Gratuito
              </p>
              <Link to="/cursos">
                <Button type="primary" size="large">
                  Ver mais
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Row>

      <Row className="last_articles_parent pt-40 pb-60">
        <Col span={24} className="container">
          <p className="blue f-38 bold text-center mt-20">Últimos Artigos New Science</p>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            grabCursor={true}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
            className="mySwiper d-flex jc-start ai-start mt-30"
          >
            {articles.slice(0, 3).map((item, index) => {
              return (
                <SwiperSlide key={"swiper-card-articles" + item.id} id={`article-${item.id}`} className="swiper-card p-20">
                  <div className="last_article_item">
                    <div
                      className="w-100 minh-170 bg-img"
                      style={{
                        backgroundImage: `url(${config.server_ip}/assets/media/${item.image_thumbnail})`,
                      }}
                    ></div>
                    <div className="p-20">
                      <p className="blue m-0 bold f-18 ellipsisOne">{item.title}</p>
                      <p className="blue m-0 f-18 ellipsisThree">{item.description}</p>
                    </div>
                    <div className="d-flex jc-end mt-20">
                      <Link to={`/artigos/${item.slug}`}>
                        <Button type="primary" size="large">
                          Ler
                        </Button>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Col>
      </Row>

      <Row className={`last_articles_parent ${windowWidth < 1000 ? "pt-0" : "pt-40"}`}>
        <Row gutter={[24]} className="container pb-0 mt-20">
          <Col span={24} className="d-flex flex-column jc-end ai-center">
            <p className="blue f-48 bold text-center mt-20 mobile-f-27">App Cardio4all disponível para download</p>
            <p className="f-28 blue italic text-center mt-10 mobile-f-18">Apoio à decisão clínica no dia a dia</p>
            <div className="d-flex jc-center mt-20 flex-wrap">
              <Link to="https://play.google.com/store/apps/details?id=pt.followreference.cardio4all&pli=1" target="_blank">
                <img src={GooglePlayImage} className="m-10 maxw-200 minw-100" />
              </Link>
              <Link to="https://apps.apple.com/ae/app/cardio4all/id1524495310" target="_blank">
                <img src={AppStoreImage} className="m-10 maxw-200 minw-100" />
              </Link>
            </div>
            <img src={MockupImage} className="w-100 mt-20" style={{ maxWidth: "1100px" }} />
          </Col>
        </Row>
      </Row>

      <Row className="position-relative container_fluid content-ricardo">
        <Row className="container">
          <Col span={24}>
            <Row gutter={[8, 8]} style={{ width: "100%" }} align={"middle"} className="section-home-ricardo d-flex jc-end">
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <h4 className="white f-26 bold mb-20">
                  <b>PARTILHA. ATUALIZAÇÃO. PRÁTICA CLÍNICA</b>
                </h4>
                <p className="white f-16 mb-20">
                  O <b>Cardio4All</b> é uma nova <b>iniciativa de formação médica dirigida aos não cardiologistas</b>, sobretudo aos colegas de <b>Medicina Geral e Familiar</b> e
                  de
                  <b>Medicina Interna</b>.
                </p>
                <p className="white f-16">
                  O objetivo é trazer <b>formação científica de alta qualidade</b>, que seja
                  <b>eminentemente prática clínica e interativa</b>, para ajudar os clínicos a <b>melhorarem o diagnóstico e tratamento das doenças cardiovasculares</b>.
                </p>
                <p className="white f-20 mb-10 text-right mt-60 italic">
                  <b>RICARDO FONTES DE CARVALHO</b>
                </p>
                <p className="white f-16 text-right italic">Cardiologista do Centro Hospitalar de Vila Nova de Gaia/Espinho</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>

      <Row className="position-relative container_fluid newsletter_container">
        <Row className="container pt-100 pb-100" id="section-mobile-mockup">
          <Col xs={24} sm={24} md={24} lg={12} xl={12} className="col-content-mockup">
            <p className="white f-36 bold mb-10">Newsletter Cardio4All</p>
            <p className="white f-18">Quer estar atualizado sobre as principais novidades na área cardiovascular? Inscreva-se na newsletter Cardio4All</p>
            <Form form={form} onFinish={handleSubmit} className="newsletter_form mt-20">
              <div className="d-flex jc-sb w-100">
                <Form.Item
                  className="w-100"
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Verifique se o e-mail que inseriu é válido.",
                    },
                  ]}
                >
                  <Input size="large" placeholder="newsletter@exemplo.pt" />
                </Form.Item>
                <Button size="large" onClick={form.submit} loading={isButtonLoading} className="minw-200 ml-20" type="primary">
                  Inscrever-me
                </Button>
              </div>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="hide-mobile"></Col>
        </Row>
      </Row>
    </>
  );
};

export default HomeWhiteSection;
