import React, { useEffect, useState } from "react";
import { Col, Row, Space, Button, Form, Input, Checkbox, Spin, message, Alert } from "antd";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ImageCoverBg from "../../../assets/images/bg-perfil@2x.png";
import endpoints from "../../../services/endpoints";
import api from "../../../services/api";
import "./Login.css";
import GoogleReCAPTCHA from "../../../services/recaptcha";
import { Helmet } from "react-helmet";

const Login = ({ user, handleLogin }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    handleVerifyUser();
  }, [user]);

  function handleVerifyUser() {
    if (user) {
      navigate("/perfil");
    }
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.auth.login, {
        data: values,
      })
      .then((res) => {
        console.log(res.data.token);
        if (res.data.user_exists && res.data.user_password) {
          console.log(res);
          api
            .axiosInit(res.data.token)
            .then((result) => {
              localStorage.setItem("token", res.data.token);
              message.success("Bem-vindo");
              console.log(location);
              let search = location.search && location.search.includes("redirect") ? location.search.split("?redirect=")[1] : "";
              handleLogin(res.data.user, `${search}`);
            })
            .catch((err) => {
              console.log(err);
              setIsButtonLoading(false);
            });
        } else {
          if (!res.data.user_exists) {
            message.error("Este e-mail não está registado");
          } else if (!res.data.user_password) {
            message.error("A password associada a este e-mail está errada");
          }
        }
        setIsButtonLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  const [isVerified, setIsVerified] = useState(true);
  const handleVerify = (value) => {
    setIsVerified(!!value);
  };

  return (
    <>
      <Helmet>
        <title>Login Cardio4all | Formação Científica em Medicina Cardiovascular</title>
        <meta
          name="description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta
          name="keywords"
          content="Cardio4all, Medicina Cardiovascular, formação científica médica, prática clínica cardiologia, doenças cardiovasculares, calculadoras área cardiovascular, formação médica em Medicina Geral e Familiar e de Medicina Interna, diagnóstico e tratamento da doença cardiovascular"
        />
        <meta property="og:title" content="Cardio4all | Formação Científica em Medicina Cardiovascular" />
        <meta
          property="og:description"
          content="O objetivo Cardio4all é trazer formação científica de alta qualidade, que seja eminentemente prática clínica e interativa, para ajudar os clínicos a melhorarem o diagnóstico e tratamento das doenças cardiovasculares."
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <GoogleReCAPTCHA onVerify={handleVerify} />
      <Row
        className="h-100 w-100 login_content d-flex jc-center ai-center"
        style={{
          minHeight: "calc(100vh - 150px)",
          backgroundImage: `url(${ImageCoverBg})`,
        }}
      >
        <Col span={24} className="container d-flex flex-column jc-center ai-center">
          <Row className="login-row d-flex jc-center ai-center">
            <Col xs={24} sm={24} md={12} lg={12} xl={8} className="d-flex flex-column jc-center ai-center">
              <p className="f-20 blue mb-30 text-center">
                Faça aqui o{" "}
                <b>
                  <i>login</i>
                </b>
                <br />
                para poder aceder à sua conta.
              </p>
              <Form className="login-form w-100" name="basic" onFinish={handleSubmit} form={form}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Introduza um endereço de e-mail válido",
                    },
                  ]}
                >
                  <Input size="large" placeholder="E-mail" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "A password deve ter pelo menos 8 caracteres",
                    },
                  ]}
                >
                  <Input.Password size="large" placeholder="Password" />
                </Form.Item>

                <div className="d-flex jc-center ai-center">
                  <Form.Item name="remember" valuePropName="checked" className="mb-15 blue">
                    <Checkbox className="blue">Lembrar-me</Checkbox>
                  </Form.Item>
                </div>

                <div className="d-flex jc-center ai-center mb-20">
                  <Link className="blue underline text-center" to="/reset-password-email">
                    <p className="f-14">Esqueceu-se da sua password?</p>
                  </Link>
                </div>

                <div className="d-flex jc-center ai-center mb-20">
                  <p className="f-14">
                    Ainda não tem conta?{" "}
                    <Link className="blue bold text-center" to="/registo">
                      Registe-se aqui
                    </Link>
                    .
                  </p>
                </div>
                <div className="d-flex jc-center ai-center">
                  <Button loading={isButtonLoading} type="primary" htmlType="submit" className="login-btn mr-10" disabled={!isVerified}>
                    Entrar
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Login;
