import "./App.css";
import { createBrowserRouter, RouterProvider, Router, useNavigate, Routes, Route, Navigate, useSearchParams, useLocation } from "react-router-dom";
import Home from "./pages/website/Home/Home";
import Articles from "./pages/website/Articles/Articles";

import Login from "./pages/website/Login/Login";
import Register from "./pages/website/Register/Register";
import Profile from "./pages/website/Profile/Profile";

import Podcasts from "./pages/website/Podcasts/Podcasts";
import PracticalGuides from "./pages/website/PracticalGuides/PracticalGuides";
import PracticalGuideDetails from "./pages/website/PracticalGuideDetails/PracticalGuideDetails";
import Admin from "./layouts/dashboard/Admin";
import MainLayout from "./layouts/website/MainLayout";
import Main from "./components/dashboard/Main";

import DashboardBanners from "./pages/dashboard/Banners/Banners";
import DashboardPodcasts from "./pages/dashboard/Podcasts/Podcasts";
import DashboardMedia from "./pages/dashboard/Media/Media";
import DashboardArticles from "./pages/dashboard/Articles/Articles";
import DashboardCreateArticle from "./components/dashboard/article/Create";
import DashboardUpdateArticle from "./components/dashboard/article/Update";
import DashboardTags from "./pages/dashboard/Tags/Tags";
import DashboardVideos from "./pages/dashboard/Videos/Videos";
import DashboardCalculators from "./pages/dashboard/Calculators/Calculators";

import PageNotFound from "./pages/website/PageNotFound/PageNotFound";
import Calculadoras from "./pages/website/Calculator/Calculators";
import PoliticaPrivacidadePage from "./pages/website/politicaPrivacidade";
import PoliticaCookiesPage from "./pages/website/politicaCookies";
import TermosCondicoesPage from "./pages/website/termosCondicoes";

import api from "./services/api";
import CalculatorSCORE2 from "./pages/website/Calculators/CalculatorSCORE2";
import CalculatorH2FPEF from "./pages/website/Calculators/CalculatorH2FPEF";
import CalculatorCHADSVASC from "./pages/website/Calculators/CalculatorCHADSVASC";
import CalculatorHASBLED from "./pages/website/Calculators/CalculatorHASBLED";
import CalculatorClearanceCreatinine from "./pages/website/Calculators/CalculatorClearanceCreatinine";
import CalculatorHipocoagulationFA from "./pages/website/Calculators/CalculatorHipocoagulationFA";
import Calculator07Page from "./pages/website/Calculators/CalculatorCVGlobalElderly";
import CalculatorCVGlobalPage from "./pages/website/Calculators/CalculatorCVGlobal";
import CalculatorLDLPage from "./pages/website/Calculators/CalculatorLDL";
import CalculatorDislipidemiaPage from "./pages/website/Calculators/CalculatorDislipidemia";
import CalculatorFagerstromPage from "./pages/website/Calculators/CalculatorFagerstrom";
import CalculatorCVGlobalSCORE2Page from "./pages/website/Calculators/CalculatorCVGlobalSCORE2";
import CalculatorCKDEPI2021Page from "./pages/website/Calculators/CalculatorCKDEPI2021";
import CalculatorPeptideosNatriureticosPage from "./pages/website/Calculators/CalculatorPeptideosNatriureticos";

import ArticleDetails from "./pages/website/ArticleDetails/ArticleDetails";
import { ConfigProvider } from "antd";
import ResetPasswordEmail from "./pages/website/ResetPasswordEmail/ResetPasswordEmail";
import ResetPassword from "./pages/website/ResetPassword/ResetPassword";
import AuthGuard from "./layouts/website/AuthGuard";
import { useEffect, useState } from "react";
import axios from "axios";
import ReactGA from "react-ga4";

import endpoints from "./services/endpoints";
import DashboardGuides from "./pages/dashboard/Guides/Guides";
import DashboardGuideModules from "./pages/dashboard/Guides/GuideModules";
import FormSuggestions from "./pages/website/FormSuggestions/FormSuggestions";
import Newsletters from "./pages/website/Newsletters/Newsletters";
import DashboardNewsletter from "./pages/dashboard/Newsletter/Newsletter";
import DashboardUsers from "./pages/dashboard/Users/Users";
import TagFilter from "./pages/website/TagFilter/TagFilter";
import DashboardEventsUsers from "./pages/dashboard/Events/Events";

import DashboardCourses from "./pages/dashboard/Courses/Courses";
import DashboardCourseItem from "./pages/dashboard/CourseItem/CourseItem";
import CoursesDetails from "./pages/website/CoursesDetails/CoursesDetails";
import MedicalAreas from "./pages/dashboard/MedicalAreas/MedicalAreas";
import Videocast from "./pages/website/Videocast/Videocast";
import PracticalGuideTopic from "./pages/website/PracticalGuideTopic/PracticalGuideTopic";
import DashboardClinicalCases from "./pages/dashboard/ClinicalCases/ClinicalCases";
import ClinicalCases from "./pages/website/ClinicalCases/ClinicalCases";
import ClinicalCaseDetails from "./pages/website/ClinicalCases/ClinicalCaseDetails";
import Courses from "./pages/website/Courses/Courses";
import ClinicalCasesOrientation from "./pages/website/ClinicalCases/ClinicalCasesOrientation";
import Contact from "./pages/website/Contact/Contact";
import dayjs from "dayjs";
import "dayjs/locale/pt";
import ScientificVideocast from "./pages/website/Videocast/ScientificVideocasts";

dayjs.locale("pt");
console.log(dayjs.locale());
api.axiosCreate();
ReactGA.initialize("G-R6R2NRM6YV");

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [location]);

  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    handleVerifyUser();
  }, []);

  function handleVerifyUser() {
    let token = localStorage.getItem("token");
    if (token) {
      axios
        .get(endpoints.user.read, {
          params: { token },
        })
        .then((res) => {
          api.axiosInit(token).then((result) => {
            setIsLoggedIn(true);
            setUser(res.data.token_decoded);
            setTimeout(() => {
              setIsLoading(false);
            }, [3000]);
          });
        })
        .catch((err) => {
          console.log(err);
          setUser(null);
          setIsLoggedIn(false);
          setTimeout(() => {
            setIsLoading(false);
          }, [3000]);
        });
    } else {
      setUser(null);
      setIsLoggedIn(false);
      setTimeout(() => {
        setIsLoading(false);
      }, [3000]);
    }
  }

  function handleLogin(userData, link) {
    setUser(userData);
    setIsLoggedIn(true);
    let redirect = searchParams.get("redirect");
    console.log(link);
    console.log(redirect);
    if (link) {
      navigate(link);
    } else {
      if (redirect) {
        navigate(redirect);
      } else {
        navigate("/perfil");
      }
    }
  }

  function handleLogOut() {
    localStorage.removeItem("token");
    setUser(null);
    setIsLoggedIn(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate("/");
    }, 1500);
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#0E426A",
          fontFamily: "Poppins",
        },
      }}
    >
      {isLoading ? (
        <div id="loader"></div>
      ) : (
        <Routes>
          <Route element={<MainLayout user={user} isLoggedIn={isLoggedIn} handleLogOut={handleLogOut} />}>
            <Route path="/" element={<Home />} exact />
            <Route path="/artigos" element={<Articles />} exact />
            <Route path="/artigos/:slug" element={<ArticleDetails />} exact />
            <Route path="/login" element={<Login user={user} handleLogin={handleLogin} />} exact />
            <Route path="/registo" element={<Register user={user} />} exact />
            <Route path="/reset-password-email" element={<ResetPasswordEmail user={user} />} exact />
            <Route path="/reset-password" element={<ResetPassword user={user} />} exact />
            <Route path="/perfil" element={<Profile />} exact />
            <Route path="/politica-de-privacidade" element={<PoliticaPrivacidadePage />} exact />
            <Route path="/politica-de-cookies" element={<PoliticaCookiesPage />} exact />
            <Route path="/termos-e-condicoes" element={<TermosCondicoesPage />} exact />
            <Route path="/sugestoes" element={<FormSuggestions />} exact />
            <Route path="/contacto" element={<Contact />} exact />
            <Route path="/tag" element={<TagFilter />} exact />

            <Route element={<AuthGuard user={user} isLoggedIn={isLoggedIn} handleLogin={handleLogin} />}>
              <Route path="/guias-praticos" element={<PracticalGuides />} exact />
              <Route path="/guias-praticos/:slug" element={<PracticalGuideDetails />} exact />
              <Route path="/guias-praticos/:slug/:topic_slug" element={<PracticalGuideTopic />} exact />
              <Route path="/casos-clinicos" element={<ClinicalCases />} exact />
              <Route path="/casos-clinicos/:slug" element={<ClinicalCaseDetails />} exact />
              <Route path="/casos-clinicos/:slug/orientacoes" element={<ClinicalCasesOrientation />} exact />
              <Route path="/podcasts" element={<Podcasts />} exact />
              <Route path="/videocasts" element={<Videocast />} exact />
              <Route path="/videocasts/atualizacao-cientifica" element={<ScientificVideocast />} exact />
              <Route path="/cursos" element={<Courses />} exact />
              <Route path="/cursos/:slug" element={<CoursesDetails />} exact />
              <Route path="/newsletters" element={<Newsletters />} exact />
              {/* Calculadoras */}
              <Route path="/calculadoras" element={<Calculadoras />} exact />
              <Route path="/calculadoras/score2-diabetes" element={<CalculatorSCORE2 />} exact />
              <Route path="/calculadoras/score-h2fpef" element={<CalculatorH2FPEF />} exact />
              <Route path="/calculadoras/score-chadsvasc" element={<CalculatorCHADSVASC />} exact />
              <Route path="/calculadoras/score-hasbled" element={<CalculatorHASBLED />} exact />
              <Route path="/calculadoras/clearance-da-creatina" element={<CalculatorClearanceCreatinine />} exact />
              <Route path="/calculadoras/selecao-da-dose-de-hipocoagulacao-da-fa" element={<CalculatorHipocoagulationFA />} exact />
              <Route path="/calculadoras/risco-cv-global-idosos-score2" element={<Calculator07Page />} exact />
              <Route path="/calculadoras/avaliacao-do-risco-cv-global" element={<CalculatorCVGlobalPage />} exact />
              <Route path="/calculadoras/calculo-do-valor-de-ldl" element={<CalculatorLDLPage />} exact />
              <Route path="/calculadoras/selecao-da-terapeutica-da-dislipidemia" element={<CalculatorDislipidemiaPage />} exact />
              <Route path="/calculadoras/teste-de-fagerstrom-adaptado" element={<CalculatorFagerstromPage />} exact />
              <Route path="/calculadoras/interpretacao-dos-valores-de-peptideos-natriureticos" element={<CalculatorPeptideosNatriureticosPage />} exact />
              <Route path="/calculadoras/risco-cv-global-score2" element={<CalculatorCVGlobalSCORE2Page />} exact />
              <Route path="/calculadoras/ckd-epi-2021" element={<CalculatorCKDEPI2021Page />} exact />
            </Route>
            <Route path="/*" element={<PageNotFound />} exact />
          </Route>

          <Route element={<Admin user={user} isLoggedIn={isLoggedIn} handleLogOut={handleLogOut} />}>
            <Route exact path="/admin/" element={<Main />} />
            <Route exact path="/admin/articles/create" element={<DashboardCreateArticle />} />
            <Route exact path="/admin/articles/update/:id" element={<DashboardUpdateArticle />} />
            <Route exact path="/admin/banners" element={<DashboardBanners />} />
            <Route exact path="/admin/guides" element={<DashboardGuides />} />
            <Route exact path="/admin/guides/:id" element={<DashboardGuideModules />} />
            <Route exact path="/admin/clinical-cases" element={<DashboardClinicalCases />} />
            <Route exact path="/admin/calculadoras" element={<DashboardCalculators />} />
            <Route exact path="/admin/media" element={<DashboardMedia />} />
            <Route exact path="/admin/podcasts" element={<DashboardPodcasts />} />
            <Route exact path="/admin/videos" element={<DashboardVideos />} />
            <Route exact path="/admin/cursos" element={<DashboardCourses />} />
            <Route exact path="/admin/cursos/:id" element={<DashboardCourseItem />} />
            <Route exact path="/admin/articles" element={<DashboardArticles />} />
            <Route exact path="/admin/tags" element={<DashboardTags />} />
            <Route exact path="/admin/medicalAreas" element={<MedicalAreas />} />
            <Route exact path="/admin/newsletters" element={<DashboardNewsletter />} />
            <Route exact path="/admin/users" element={<DashboardUsers />} />
            <Route exact path="/admin/events" element={<DashboardEventsUsers />} />
          </Route>
        </Routes>
      )}
    </ConfigProvider>
  );
}
export default App;
