import React from "react";
import { EyeOutlined } from "@ant-design/icons";
import CountUp from "react-countup";
import { Col, Row, Space, Statistic, Button, Form, Input, Checkbox } from "antd";
import BgGuiasPraticos from "../../../assets/images/guias-praticosk-bg.png";
import config from "../../../services/config";
import "./BannerPrincipal.css";

import { Outlet, Link } from "react-router-dom";

const BannerPrincipal = (props) => {
  return (
    <Row
      className="banner_principal"
      style={{
        backgroundImage: `linear-gradient(90deg, ${props.color} 100%, rgba(255, 255, 255, 0) 100%)`,
      }}
    >
      <Row className="background_img_row">
        <Col xs={0} sm={0} md={0} lg={12} xl={12}></Col>
        <Col
          className="background_img_col"
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{
            backgroundImage: props.backgroundImage?.includes("static") ? `url(${props.backgroundImage})` : `url(${config.server_ip}/assets/media/${props.backgroundImage})`,
          }}
        ></Col>
      </Row>
      <Col span={24} className="container content_banner">
        <Row gutter={[24]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            {props.backText && (
              <Link className="blue underline" to={props.backLink}>
                <p className="mb-40">{props.backText}</p>
              </Link>
            )}
            {props.children}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}></Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BannerPrincipal;
